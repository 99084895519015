import {
  AppPlayer,
  EventFromElm,
  EventToElm,
  InputMaskTc,
  RawFrameParams,
  ShakaPlayer,
  Timecode as TimecodeType,
  Track,
  TrackId
} from './type';
import { render } from './rawImagePopup';
import { inputMaskTimecode } from './input-mask';
import { Timecode } from './timecode';

const braille = require('./braille');
const common = require('./common');
const shaka = require('shaka-player/dist/shaka-player.ui');

const $ = common.$;
var shakaVideoContainer : HTMLDivElement;

// shaka buffer config (in second)
const BUFFERBEHIND = 5 * 60;
const BUFFERINGGOAL = 5 * 60;

const CONSTANT_VIDEO_INTERACTIVITY = 0.00001;
const IS_IOS = /iPad|iPhone|iPod/.test(navigator.platform)
  || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

export default function Navigation(app: AppPlayer) {
  function sendEventToElm(event: EventToElm): void {
    app.ports.jsToElm?.send(Object.assign({
      action: '',
      buffer: [{
        start: 0,
        end: 0
      }],
      frame: 0
    }, event));
  }

  function preventDefaultWindowOnSpace(event: KeyboardEvent) {
    if (event.key == ' '
      && !['INPUT', 'TEXTAREA'].includes((event.target as Element).nodeName)) {
      event.preventDefault();
    }
  }

  function computeCurrentFrame(video: HTMLVideoElement, frameRate: number, supportFrameAccuracy: boolean): number {
    if (supportFrameAccuracy) {
      return braille(video);
    }
    else {
      return Math.floor(video.currentTime * frameRate);
    }
  }

  class Nomaplayer extends HTMLElement {
    state = {
      selectedTracks: [] as Array<number>,
      audiotracks: [] as Array<TrackId>,
      manifest: '',
      player: {} as ShakaPlayer,
      currentSubtitle: '',
      frameRate: 0,
      startTimecode: '00:00:00:00'
    };
    frameAccuracySupport = true;

    // Timecode input
    maskInput = {} as InputMaskTc;
    timecodeMaskElt = {} as HTMLInputElement;

    // Timecode overlay
    timeMaskElt = {} as HTMLInputElement;
    timeCursorElt = {} as HTMLElement;
    timeTCElt = {} as HTMLElement;

    ui : any;

    // Proxy `this` inside listener
    videoProgressListener = (event: Event) => this.onVideoProgress(event);
    inputFocusListener = () => this.getVideo().pause();
    inputBlurListener = () => setTimeout(() => this.getVideo().focus(), 10);
    moveTimecodeListener = (event: MouseEvent) => this.moveTimecode(event);
    requestFrameTimecodeOverlayListener = () => this.requestFrameTimecodeOverlay();
    portsFromElm = (event: EventFromElm) => this.eventFromElm(event);
    onTimeMaskClickListener = (event: MouseEvent) => this.onTimeMaskClick(event);

    constructor() {
      super();
      shaka.polyfill.installAll();
      this.attachShadow({ mode: 'open' });

      this.frameAccuracySupport = !(!shaka.Player.isBrowserSupported() || IS_IOS);

      const videoCtrl = document.createElement('div');
      shakaVideoContainer = videoCtrl;
      const videoStyles = document.createElement('style');
      videoStyles.innerHTML = `
.shaka-hidden{display:none!important}
.shaka-video-container{pointer-events:none;position:absolute;left:1vw;right:1vw;top:1vw;bottom:1vw;display:flex}

.shaka-text-container{position:absolute;left:0;right:0;top:0;bottom:0;font-size:1vw;line-height:1.4;color:#fff}
.shaka-text-container span.shaka-text-wrapper{display:inline;background:0 0}
@media (min-width: 1000px) and (max-width: 1400px) { .shaka-text-container { font-size: 1.55vw; } }
@media (max-width: 1000px) { .shaka-text-container { font-size: 2.2vw; } }
.PlayerFullScreen .shaka-text-container { font-size: 2.2vw !important; }
.shaka-text-container * { background-color: rgba(0,0,0,0) !important; }
.shaka-text-container {
  text-shadow: 0 0 0.11vw #000, 0 0 2px #000, 0 0 2px #000;
}
.PlayerControlsShown.shaka-video-container { bottom: calc(90px + 1vw); }

.shaka-spinner-container{display:none;}
      `

      const video = document.createElement('video');
      video.setAttribute('style', 'width: 100%;display: block;margin-top: -3.68%;');
      video.setAttribute('id', 'video-player');
      video.setAttribute('playsinline', '');
      video.setAttribute('crossOrigin', 'anonymous');

      this.shadowRoot?.appendChild(video);
      this.shadowRoot?.appendChild(videoCtrl);
      this.shadowRoot?.appendChild(videoStyles);
    }

    connectedCallback(): void {
      this.initVariables();
      this.render();
      app.ports.elmToJs?.subscribe(this.portsFromElm);
    }

    disconnectedCallback() {
      app.ports.elmToJs?.unsubscribe(this.portsFromElm);
      this.maskInput.destroy();
      this.ui.destroy();
      return this.state.player.destroy()
        .then(() => {
          this.state = {
            selectedTracks: [] as Array<number>,
            audiotracks: [] as Array<TrackId>,
            manifest: '',
            player: {} as ShakaPlayer,
            currentSubtitle: '',
            frameRate: 0,
            startTimecode: '00:00:00:00',
          };
          this.timecodeMaskElt.removeEventListener('focus', this.inputFocusListener);
          this.timecodeMaskElt.removeEventListener('blur', this.inputBlurListener);
          this.timeMaskElt.removeEventListener('mousemove', this.moveTimecodeListener);
          this.timeMaskElt.removeEventListener('mouseleave', this.requestFrameTimecodeOverlayListener);
          this.timeMaskElt.removeEventListener('click', this.onTimeMaskClickListener);
          this.getVideo().removeEventListener('ended', this.ended);
          this.getVideo().removeEventListener('progress', this.onVideoLoading);
          this.getVideo().removeEventListener('timeupdate', this.videoProgressListener);
          this.getVideo().removeEventListener('click', this.playOrPause);
          this.getVideo().removeEventListener('dblclick', this.handleFullScreen);
          window.removeEventListener('keydown', preventDefaultWindowOnSpace);
          window.removeEventListener('keyup', this.keyUpVideo);
        });
    }

    initVariables(): void {
      const manifest = this.getAttribute('data-manifest');
      if (manifest) {
        this.state.manifest = manifest;
      }

      const audiostracks = this.getAttribute('data-audiostracks');
      if (audiostracks) {
        this.state.audiotracks = JSON.parse(audiostracks);
        if (!this.state.audiotracks.length) {
          this.state.audiotracks.push('1');
        }
      }

      const playbackSpeed = this.getAttribute('data-playbackspeed');
      if (playbackSpeed) {
        this.getVideo().playbackRate = parseFloat(playbackSpeed);
      }

      const subtitles = this.getAttribute('data-subtitles');
      if (subtitles) {
        this.state.currentSubtitle = subtitles;
      }

      const framerate = this.getAttribute('data-framerate');
      if (framerate) {
        this.state.frameRate = parseFloat(framerate);
      }

      const startTimecode = this.getAttribute('data-starttimecode');
      if (startTimecode) {
        this.state.startTimecode = startTimecode;
      }
    }

    static get observedAttributes(): Array<string> {
      return [
        'data-manifest',
        'data-audiostracks',
        'data-playbackspeed',
        'data-subtitles',
        'data-framerate',
        'data-starttimecode'
      ];
    }

    attributeChangedCallback(name: string, _: any, newValue: any) {
      switch (name) {
        case 'data-audiostracks':
          this.state.audiotracks = JSON.parse(newValue);
          if (Object.entries(this.state.player).length !== 0) {
            this.loadVideo();
          }
          break;
        case 'data-manifest':
          const isInitialized = this.state.manifest !== '';
          this.state.manifest = newValue;
          // Clear the current player if the manifest change and it was already initialized
          if (isInitialized) {
            if (this.state.player.destroy) {
              return this.state.player.destroy()
                .then(() => this.initPlayer());
            }
            this.initPlayer();
          }
          break;
        case 'data-starttimecode':
          this.state.startTimecode = newValue;
          break;
        case 'data-playbackspeed':
          if (newValue) {
            this.getVideo().playbackRate = parseFloat(newValue);
          }
          break;
        case 'data-framerate':
          if (newValue) {
            this.state.frameRate = parseFloat(newValue);
          }
          break;
        case 'data-subtitles':
          if (newValue != null) {
            this.state.currentSubtitle = newValue;
            if (this.state.player.destroy) {
              return this.state.player.destroy()
                .then(() => this.initPlayer());
            }
            this.initPlayer();
          }
          break;
        default:
      };
    }

    eventFromElm(event: EventFromElm): void {
      switch (event.action) {
        case 'Play':
          this.play();
          break;
        case 'Pause':
          this.pause();
          break;
        case 'GoTo':
          if (event.frame !== undefined) {
            this.goToFrame(event.frame);
          }
          break;
        case 'GoToStart':
          this.goToStart();
          break;
        case 'GoToEnd':
          this.goToEnd();
          break;
        case 'SubstractTime':
          if (event.seconds !== undefined) {
            this.substractTime(event.seconds);
          }
          break;
        case 'AddTime':
          if (event.seconds !== undefined) {
            this.addTime(event.seconds);
          }
          break;
        case 'NextFrame':
          this.nextFrame();
          break;
        case 'PrevFrame':
          this.prevFrame();
          break;
        case 'Fullscreen':
          this.handleFullScreen();
          break;
        case 'Aspectratio':
          if (event.trackSelection !== undefined) {
            this.state.selectedTracks = event.trackSelection;
          }
          break;
        case 'GetPicture':
          if (event.rawFrame !== undefined) {
            this.rawFrame(event.rawFrame);
          }
          break;
      }
    }

    play(): void {
      this.getVideo().play();
    }

    pause(): void {
      this.getVideo().pause();
    }

    playOrPause(event: any): void {
      const videoElt = event.currentTarget as HTMLVideoElement;
      if (videoElt.paused) {
        videoElt.play();
      }
      else {
        videoElt.pause();
      }
    }

    substractTime(seconds: number): void {
      if (this.getVideo().currentTime - seconds > 0) {
        this.getVideo().currentTime -= seconds;
      }
      else {
        this.getVideo().currentTime = 0;
      }
    }

    addTime(seconds: number): void {
      if (this.getVideo().currentTime + seconds < this.getVideo().duration) {
        this.getVideo().currentTime += seconds;
      }
      else {
        // Since setting the duration of the video make it bug, just remove the little constant to make it works
        this.getVideo().currentTime = this.getVideo().duration - CONSTANT_VIDEO_INTERACTIVITY;
      }
    }

    goToFrame(frame: number): void {
      const currentFrame = computeCurrentFrame(this.getVideo(), this.state.frameRate, this.frameAccuracySupport);
      if (!isNaN(currentFrame)) {
        const lastFrameNumber = Math.floor(this.state.frameRate * this.getVideo().duration) - 1;
        if (frame < 0) {
          frame = 0;
        }
        if (frame > lastFrameNumber) {
          frame = lastFrameNumber;
        }
        this.getVideo().currentTime = (frame / this.state.frameRate) + CONSTANT_VIDEO_INTERACTIVITY;
      }
    }

    nextFrame(): void {
      const currentFrame = computeCurrentFrame(this.getVideo(), this.state.frameRate, this.frameAccuracySupport);
      if (!isNaN(currentFrame)) {
        const lastFrameNumber = Math.floor(this.state.frameRate * this.getVideo().duration) - 1;
        if (currentFrame < lastFrameNumber) {
          this.getVideo().currentTime = ((currentFrame + 1) / this.state.frameRate) + CONSTANT_VIDEO_INTERACTIVITY;
        }
      }
    }

    prevFrame(): void {
      const currentFrame = computeCurrentFrame(this.getVideo(), this.state.frameRate, this.frameAccuracySupport);
      if (!isNaN(currentFrame)) {
        const frameNumber = isNaN(currentFrame) ? null : currentFrame;
        if (frameNumber != null && frameNumber > 0) {
          this.getVideo().currentTime = ((currentFrame - 1) / this.state.frameRate) + CONSTANT_VIDEO_INTERACTIVITY;
        }
      }
    }

    goToStart(): void {
      this.getVideo().currentTime = 0;
    }

    goToEnd(): void {
      const totalFrames = Math.round(this.state.frameRate * this.getVideo().duration);
      this.getVideo().currentTime = (totalFrames - 1) / this.state.frameRate + CONSTANT_VIDEO_INTERACTIVITY;
    }

    rawFrame(params: RawFrameParams): void {
      const currentFrame = computeCurrentFrame(this.getVideo(), this.state.frameRate, this.frameAccuracySupport);
      const frameNumber = isNaN(currentFrame) ? null : currentFrame;
      if (frameNumber != null) {
        const seconds = frameNumber / params.frameRate;
        const imageUrl = `${window.nomalab.raw_frame_url}/?bucket=${params.bucket}&key=${params.key}&ms=${seconds}&user=${window.nomalab.user.id}`;
        const newWin = open('', '', 'height=1920,width=1080,toolbar=no,location=no,directories=no,status=no');
        if (newWin) {
          newWin.document.write(render(imageUrl));
        }
      }
    }

    getVideo(): HTMLVideoElement {
      return this.shadowRoot?.childNodes[0] as HTMLVideoElement;
    }

    getMpdUrl(): string {
      const subtitle = this.state.currentSubtitle ? `?subtitle=${this.state.currentSubtitle}` : '';
      return `/v3/files/${this.state.manifest}/manifest${subtitle}`;
    }

    getMp4Url(): Promise<string> {
      return fetch(this.getMpdUrl()).then((resp : any) => {
        if (resp.ok) {
          return resp.text().then((xml : any)=> {
            let url = xml.match(/>([^>]+video_[^<]+\.mp4[^<]+)</g)[0].slice(1,-1)
            let txt = document.createElement("textarea")
            txt.innerHTML = url
            return txt.value
          })
        }
      })
    }

    // TODO Move this back in the elm
    displaySelectedVideo(): void {
      const videoStream = $('[name=selected-video-track]');
      if (videoStream) {
        videoStream.checked = true;
      }
    }

    onTimeMaskClick(event: MouseEvent): void {
      const b = this.timeMaskElt.getBoundingClientRect();
      const frame = Math.round((event.x - b.x) / b.width * this.state.frameRate * (this.getVideo().duration - (1 / this.state.frameRate)));
      this.goToFrame(frame);
    }

    keyUpVideo = (event: any) => {
      if (!(event.target.tagName === 'INPUT' || event.target.tagName === 'SELECT' || event.target.tagName === 'TEXTAREA')) {
        switch (event.keyCode) {
          // space
          case 32:
            if (this.getVideo().paused)
              this.play();
            else
              this.pause();
            break;
          // Left arrow
          case 37:
            this.prevFrame();
            break;
          // Right arrow
          case 39:
            this.nextFrame();
        }
      }
    }

    loadVideoOnly(): void {
      this.getMp4Url().then((url : string)=> {
        this.getVideo().src = url
        this.getVideo().play()
      })
    }

    loadVideo(): void {
      this.state.player.setTextTrackVisibility(this.state.currentSubtitle != '' && this.state.currentSubtitle != null);

      const tracks = this.state.player.getVariantTracks();
      const trackIndex = parseInt(this.state.audiotracks[0]);
      const selectedTrack = tracks.find((el: Track) => {
        if (el.label?.includes('audio_stream')) {
          const searchId = new RegExp("audio_stream_([^//]+)$");
          const trackId = parseInt(el.label.replace(searchId, '$1'));
          return trackId === (trackIndex || 0);
        }
        return false
      });
      if (selectedTrack) {
        this.displaySelectedVideo();
        app.ports.updateAudioStreams.send([[trackIndex, null]]);
        this.state.player.selectVariantTrack(selectedTrack, true);
      }

      // Timecode input
      const timecodeMaskElt = $('.TimecodeEditable');
      if (timecodeMaskElt) {
        this.timecodeMaskElt = timecodeMaskElt;
        const lastTC = Timecode.init({ framerate: this.state.frameRate, timecode: this.state.startTimecode });
        lastTC.add([Math.floor(this.state.frameRate * this.getVideo().duration) - 1]);
        const tc = Timecode.init({ framerate: this.state.frameRate, timecode: this.state.startTimecode });
        this.maskInput = inputMaskTimecode(
          this.timecodeMaskElt,
          tc.frameCount,
          lastTC.frameCount,
          this.state.frameRate,
          (tc: TimecodeType) => {
            tc.subtract([this.state.startTimecode]);
            this.goToFrame(tc.frameCount);
          }
        );
        this.maskInput.setTc(tc);
        this.timecodeMaskElt.addEventListener('focus', this.inputFocusListener);
        this.timecodeMaskElt.addEventListener('blur', this.inputBlurListener);
      }

      // Timecode overlay on mousemove
      // TODO: do something better
      const timeMaskElt = $('.PlayerControlsTimeMask');
      if (timeMaskElt && !this.timeMaskElt.addEventListener) {
        this.timeMaskElt = timeMaskElt;
        this.timeMaskElt.addEventListener('mouseleave', this.requestFrameTimecodeOverlayListener);
        this.timeMaskElt.addEventListener('click', this.onTimeMaskClickListener);

        this.timeCursorElt = document.createElement('div');
        this.timeCursorElt.className = 'PlayerControlsTimeCursor';
        this.timeCursorElt.style.pointerEvents = 'none';

        this.timeTCElt = document.createElement('span');
        this.timeTCElt.className = 'PlayerControlsTimeTimecode';

        this.timeCursorElt.appendChild(this.timeTCElt);
        if (this.timeMaskElt) {
          this.timeMaskElt.appendChild(this.timeCursorElt);
          this.timeMaskElt.addEventListener('mousemove', this.moveTimecodeListener);
        }
      }

      this.state.player.addEventListener('buffering', this.buffer);
      this.getVideo().addEventListener('ended', this.ended);
      this.getVideo().addEventListener('progress', this.onVideoLoading);
      this.getVideo().addEventListener('timeupdate', this.videoProgressListener);
      this.getVideo().addEventListener('click', this.playOrPause);
      this.getVideo().addEventListener('dblclick', this.handleFullScreen);

      window.addEventListener('keydown', preventDefaultWindowOnSpace);
      window.addEventListener('keyup', this.keyUpVideo);
    }

    buffer(event: any): void {
      const action = event.buffering ? 'Buffering' : 'StopBuffering';
      sendEventToElm({ action });
    }

    ended(): void {
      sendEventToElm({ action: 'Finished' });
    }

    onVideoLoading(event: ProgressEvent): void {
      const videoElt = event.currentTarget as HTMLVideoElement;
      if (videoElt.buffered.length) {
        const eventForElm = {
          action: 'Loaded',
          buffer: [
            {
              start: videoElt.buffered.start(0),
              end: videoElt.buffered.end(0)
            }
          ]
        };
        sendEventToElm(eventForElm);
      }
    }

    onVideoProgress(event: any) {
      const videoElt = event.currentTarget as HTMLVideoElement;
      const frameNumber = computeCurrentFrame(videoElt, this.state.frameRate, this.frameAccuracySupport);
      sendEventToElm({
        action: videoElt.paused ? 'isPaused' : 'Playing',
        frame: frameNumber
      });
      this.setTcFromFrame(frameNumber);
    }

    setTcFromFrame(frame: number): void {
      const tc: TimecodeType = Timecode.init({ framerate: this.state.frameRate });
      tc.setFrameNumberToTimecode(frame);
      tc.add([this.state.startTimecode]);
      this.maskInput.setTc(tc);
    }

    moveTimecode(evt: MouseEvent): void {
      const b = this.timeMaskElt.getBoundingClientRect();
      if ((evt.x < (b.x + b.width) && evt.x >= b.x)
        && (evt.y >= b.y && evt.y <= (b.y + b.height))) {
        const frame: number = Math.round((evt.x - b.x) / b.width * this.state.frameRate * (this.getVideo().duration - (1 / this.state.frameRate)));
        const tc = Timecode.init({ framerate: this.state.frameRate });
        tc.set(frame);
        tc.add([this.state.startTimecode]);
        requestAnimationFrame(() => {
          this.timeTCElt.innerHTML = tc.toString();
          this.timeTCElt.style.transform = evt.offsetX < 80 ? `translateX(100%)` : '';
          this.timeCursorElt.style.left = `${evt.x - b.x}px`;
          this.timeMaskElt.style.opacity = '1';
        });
      }
      else {
        requestAnimationFrame(() => this.timeMaskElt.style.opacity = '0');
      }
    }

    requestFrameTimecodeOverlay(): void {
      requestAnimationFrame(() => this.timeMaskElt.style.opacity = '0');
    }

    warns(message: string): void {
      const banner = $('.PlayerBanner');
      $('.PlayerBanner .BannerContent').innerHTML = message;
      banner.style.display = '';
      banner.addEventListener('click', function h(){
        banner.style.display = 'none';
        banner.removeEventListener('click', h);
      })
    }

    handleFullScreen() {
      const videoWrapper = $('#player-wrapper');

      const controlsWrapper = $('.PlayerControlsBox');
      let hoveringControls: boolean = false;
      let controlsFullscreenDelay: any = 0;

      function playerMouseMove(): void {
        if (controlsFullscreenDelay)
          clearTimeout(controlsFullscreenDelay);

        if (!hoveringControls)
          controlsFullscreenDelay = setTimeout(hideControls, 1000);

        toggleCtrl(true)
      }

      function controlsMouseEnter(): void { hoveringControls = true }
      function controlsMouseLeave(): void { hoveringControls = false }
      function hideControls(): void {
        toggleCtrl(false)
      }

      if (videoWrapper.webkitRequestFullscreen) {
        null == document.webkitFullscreenElement ? videoWrapper.webkitRequestFullscreen() : document.webkitExitFullscreen();
      }
      else if (videoWrapper.mozRequestFullScreen) {
        null == document.mozFullScreenElement ? videoWrapper.mozRequestFullScreen() : document.mozCancelFullScreen();
      }
      else if (videoWrapper.msRequestFullscreen) {
        null == document.msFullscreenElement ? videoWrapper.msRequestFullscreen() : document.msExitFullscreen();
      }
      else if (videoWrapper.requestFullscreen) {
        null == document.fullscreenElement ? videoWrapper.requestFullscreen() : document.exitFullscreen();
      }
      else if (IS_IOS) {
        this.getVideo().webkitEnterFullscreen();
      }

      function toggleCtrl(shown?: boolean): void {
        if (shown === undefined) {
          videoWrapper.classList.remove('PlayerControlsHidden')
          shakaVideoContainer.classList.remove('PlayerControlsShown')
        } else if (shown) {
          videoWrapper.classList.remove('PlayerControlsHidden')
          shakaVideoContainer.classList.add('PlayerControlsShown')
        } else {
          videoWrapper.classList.add('PlayerControlsHidden')
          shakaVideoContainer.classList.remove('PlayerControlsShown')
        }
      }

      function toggleFullscreen(full : boolean): void {
        if (full) {
          videoWrapper.classList.add('PlayerFullScreen')
          shakaVideoContainer.classList.add('PlayerFullScreen')
        } else {
          videoWrapper.classList.remove('PlayerFullScreen')
          shakaVideoContainer.classList.remove('PlayerFullScreen')
        }
      }

      function toggle(): void {
        if (!document.webkitFullscreenElement && !document.mozFullScreenElement && !document.msFullscreenElement && !document.fullscreenElement) {
          toggleCtrl()
          toggleFullscreen(false)
          if (controlsFullscreenDelay) clearTimeout(controlsFullscreenDelay)

          videoWrapper.removeEventListener('mousemove', playerMouseMove)
          controlsWrapper.removeEventListener('mouseenter', controlsMouseEnter)
          controlsWrapper.removeEventListener('mouseleave', controlsMouseLeave)

        } else {
          toggleFullscreen(true)

          videoWrapper.addEventListener('mousemove', playerMouseMove)
          controlsWrapper.addEventListener('mouseenter', controlsMouseEnter)
          controlsWrapper.addEventListener('mouseleave', controlsMouseLeave)
        }
      }

      videoWrapper.addEventListener('fullscreenchange', toggle);
      videoWrapper.addEventListener('mozfullscreenchange', toggle);
      videoWrapper.addEventListener('MSFullscreenChange', toggle);
      videoWrapper.addEventListener('webkitfullscreenchange', toggle);
    }


    initPlayer(): void {
      // Init the shaka player
      this.state.player = new shaka.Player(this.getVideo());

      const ui = new shaka.ui.Overlay(this.state.player, shakaVideoContainer, this.getVideo());
      this.ui = ui;

      // disable shaka native controls https://shaka-player-demo.appspot.com/docs/api/shaka.extern.html#.UIConfiguration
      ui.configure({
        controlPanelElements: [],
        overflowMenuButtons: [],
        contextMenuElements: [],
        addSeekBar: false,
      });

      this.state.player.configure({
        abr: { enabled: false },
        streaming: {
          bufferBehind: BUFFERBEHIND,
          bufferingGoal: BUFFERINGGOAL
        }
      });

      this.state.player
        .load(this.getMpdUrl())
        .catch(() => {
          this.loadVideoOnly();
          if (!IS_IOS) {
            const message = navigator.language.startsWith('fr')
            ? 'Essayez de mettre à jour votre navigateur (Chrome, Edge, Firefox...) pour lire la vidéo avec le son, sinon contactez-nous.'
            :'Please try updating your brwoser (Chrome, Edge, Firefox...) to play the video with Sound, otherwise contact us.';
            this.warns(message);
          }
        })
        .then(() => {
          this.loadVideo();
          sendEventToElm({ action: 'Added' });
        });
    }

    render(): void {
      this.initPlayer();
    }
  }

  customElements.define('noma-player', Nomaplayer);
}
