var fns = require('date-fns');
var fnsLocales = require('date-fns/locale');
var localeFns = { locale: fnsLocales.fr };


/*
 * <format-time datetime="2020-06-04T12:27:06.222Z">
 */
class FormatTime extends HTMLElement {

  constructor() {
    super();
    this.useRelativeDate = false;
  }

  connectedCallback() {
    this.render();
    this.onclick = function() {
      this.useRelativeDate = !this.useRelativeDate;
      this.render();
    }
  }

  render() {
    var x = new Date(this.getAttribute('datetime'));
    this.innerHTML = this.useRelativeDate
    ? fns.formatRelative(x, new Date(), localeFns)
    : fns.format(x, 'PP, p', localeFns);
  }
}


customElements.define('format-time', FormatTime);
