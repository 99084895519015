module Private exposing (..)

import Browser
import Context
import Css.Global
import Html.Styled
import Private.Model exposing (..)
import Private.Style
import Private.Update exposing (..)
import Private.View exposing (..)


main : Program Context.Flags InitModel InitMsg
main =
    Browser.element
        { init = initModel
        , view =
            \model ->
                Html.Styled.div []
                    [ initView model
                    , Css.Global.global Private.Style.styles
                    ]
                    |> Html.Styled.toUnstyled
        , update = initUpdate
        , subscriptions = initSubscriptions
        }
