module.exports = function routing(app, portName) {
  // Manually handle the push history state
  // mostly to allow ctrl/cmd + click to open in new tab
  document.body.addEventListener('click', function (e) {
    // ignore click + meta or ctrl for new tabs
    if (e.ctrlKey || e.metaKey) return true;

    // lookup for an <a[href]>
    var element = e.target;
    while (!element.href) {
      if (!element.parentNode) return true;
      element = element.parentNode;
    }
    var href = element.getAttribute('href');

    // ignore links for <a[data-prevent-history]>
    if (element.getAttribute('data-prevent-history')) {
      return true;
    }

    // ignore external links and anchors
    if (/^https?:\/\//.test(href) || href.slice(0, 1) === '#') {
      return true;
    }

    // ignore mailto links or tel
    if (href.slice(0, 7) === 'mailto:' || href.slice(0, 4) === 'tel:') {
      return true;
    }

    // let's do the magic
    e.preventDefault();
    try {
      app.ports.newurl.send(href);
    } catch (e) {
      console.error(e);
    }
    history.pushState(null, null, href);
    return false;
  }, true);

  window.addEventListener('popstate', function () {
    app.ports.newurl.send(window.location.pathname + window.location.search);
  });

  app.ports[portName].subscribe(function (href) {
    try {
      app.ports.newurl.send(href);
    } catch (e) {
      console.error(e);
    }
    history.pushState(null, null, href);
  });

};
