
// hi-tech versioning system
if (localStorage.getItem('version') != 'v01') {
    localStorage.clear();
    localStorage.setItem('version', 'v01');
}

function defaults(name, def) {
    return {
        showAside: get('showAside', '/settings'),
        browserArchive: get('browserArchive', 'seeAll'),
        browserAccept: get('browserAccept', 'seeAll')
    };
}

function subscribe(app) {
    app.ports.setPreference.subscribe(function(value) {
        // sent as a tuple
        set(value[0],value[1]);
    });

    app.ports.resetPreference.subscribe(function(value) {
        localStorage.clear();
    });

    app.ports.downloadAll.subscribe(function(className) {
        let elems = document.getElementsByClassName(className);
        for (let i = 0; i < elems.length; i++) {
            elems[i].click();
        }
    })

}

function get(name, def) {
    return localStorage.getItem(name) || def;
}

function set(name, value) {
    localStorage.setItem(name, value);
}


module.exports = {
    defaults: defaults,
    subscribe: subscribe
} 
