
module.exports = function(app) {


  if (window.nomalab.intercom) {
    // Load intercom snippet
    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/'+window.nomalab.intercom.id;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();

    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: window.nomalab.intercom.id,
      name : window.nomalab.user.name,
      email: window.nomalab.user.email,
      alignment: "left"
    });
  }

  if (window.nomalab.sentry) {
    var sentryTag=document.createElement('script');
    sentryTag.crossOrigin="anonymous";
    sentryTag.src="https://browser.sentry-cdn.com/5.27.6/bundle.tracing.min.js";
    sentryTag.integrity="sha384-9Z8PxByVWP+gIm/rTMPn9BWwknuJR5oJcLj+Nr9mvzk8nJVkVXgQvlLGZ9SIFEJF";
    sentryTag.addEventListener('load', function(){

      Sentry.init({
        dsn: window.nomalab.sentry.url,
        release: process.env.FOREST_VERSION || 'dev',
        environment: window.nomalab.environment || 'prod',
        integrations: [new Sentry.Integrations.BrowserTracing()],
        tracesSampleRate: 0.2
      });

      if (window.nomalab.user && window.nomalab.user.email) {
        Sentry.setUser({ email: window.nomalab.user.email });
      }
    });
    document.body.appendChild(sentryTag);
  }

  app.ports.openIntercom && app.ports.openIntercom.subscribe(function(toggle) {
    if (toggle) {
      Intercom && Intercom('show');
    } else { 
      Intercom && Intercom('hide');
    }
  });

}
