
window.document.body.addEventListener("click", function(e){
  var target = e.target;
  var current = document.getElementById("CatchClickAll");
  if (!current) return true;
  do {
    if (target === current) {
      return true;
    }
  } while (target = target.parentNode);
  current.click();
}, true);

