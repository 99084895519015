"use strict";

require('./helpers/polyfill.js');

var preferences = require('./helpers/preferences.js');

var Elm = require('../src/Private.elm').Elm;

// Expose the config for everyboAdaY
window.nomalab = {
  user: null,
  isAdvertiser: false,
  aws: null,
  // nomalabEnv is exposed by config.js ()
  // See helpers/thirdParty
  sentry: null,
  intercom: null,
  raw_frame_url: null
};

window.addEventListener('load', async function () {
  const response = await fetch('/v4/config/front');
  if (response.ok) {
    const config = await response.json();
    window.nomalab.sentry = config.sentry;
    window.nomalab.intercom = config.intercom;
    window.nomalab.raw_frame_url = config.raw_frame?.url;
  }

  // Fetch user infos
  fetch('/v3/users/me')
    .then(function(response) {
      if (response.status === 200) {
        return response.json()
      } else {
        throw 'not logged'
      }
    })
    .then(function (me) {
      // Setup the user
      window.nomalab.user = me;

      return Promise.all([
        // fetch isAdvertiser status
        me.organization ? fetch(`/v4/organizations/${me.organization}/isAdvertiser`)
        .then(function(response) {
            return response.json()
          })
          .then(function (data) {
            window.nomalab.isAdvertiser = data.isAdvertiser
          }) : Promise.resolve(null),
        // fetch aws config
        fetch('/v3/aws/config')
          .then(function(response) {
            return response.json()
          })
          .then(function (awsConfig) {
            // See helpers/upload
            window.nomalab.aws = awsConfig
          }),
      ])
    })
    .catch(function(err) {
      // Not logged
    })
    .finally(function() {

      // RUN THE APP
      var app = Elm.Private.init({
        node: document.getElementById('page'),
        flags: {
          user: window.nomalab.user,
          url: window.location.pathname + window.location.search,
          lang: window.navigator.userLanguage || window.navigator.language,
          width: window.innerWidth,
          height: window.innerHeight,
          now: new Date().getTime(),
          defaults: preferences.defaults(),
          version: process.env.FOREST_VERSION || 'dev',
          isAdvertiser: window.nomalab.isAdvertiser ?? false,
        }
      });

      require('./helpers/navigation')(app);
      require('./helpers/thirdParty')(app);

      // do not bother to load when not logged
      if (window.nomalab.user) {
        require('./helpers/time.js');
        require('./helpers/format-date.js');
        require('./helpers/format-distance.js');
        require('./helpers/raf.js')(app);
        require('./helpers/routing.js')(app, 'redirect');
        require('./helpers/keyboard.js')(app);
        require('./helpers/viewport.js')(app);
        require('./helpers/clickAll.js');
        require('./helpers/nomaplayer.ts').default(app);
        require('./helpers/upload')(app);
        require('./helpers/lastAudioQualification')(app);
        preferences.subscribe(app);
      }

    });

});

