// needed for ie11
if(!Object.assign) {
  Object.assign = function (target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];
        for (var key in source) {
            if (source.hasOwnProperty(key)) {
                target[key] = source[key];
            }
        }
    }
    return target;
  };
}

// safari
// from https://github.com/uxitten/polyfill
if (!String.prototype.padStart) {
    String.prototype.padStart = function padStart(targetLength,padString) {
        targetLength = targetLength>>0;
        padString = String((typeof padString !== 'undefined' ? padString : ' '));
        if (this.length > targetLength) {
            return String(this);
        }
        else {
            targetLength = targetLength-this.length;
            if (targetLength > padString.length) {
                padString += padString.repeat(targetLength/padString.length);
            }
            return padString.slice(0,targetLength) + String(this);
        }
    };
}

/*
ES6 classes shim for custom elements

> See: https://github.com/webcomponents/custom-elements/blob/d8ecb29ad12abf79cc40ddfb4771d7673e59fce0/tests/html/shim.html
> and: https://github.com/webcomponents/custom-elements/blob/master/src/native-shim.js

EXAMPLE:

function MyTag() {
  return HTMLElement.apply(this);
}

MyTag.prototype = Object.create(HTMLElement.prototype);
MyTag.prototype.constructor = MyTag;

MyTag.prototype.connectedCallback = function() {
    // when added
}
MyTag.prototype.disconnectedCallback = function() {
    // when removed
}
MyTag.prototype.attributeChangedCallback = function() {
    // when attribute changed
}
MyTag.prototype.adoptedCallback = function() {
    // when added too?
}

*/
(function() {
  if (
    window.Reflect === undefined ||
    window.customElements === undefined ||
    window.customElements.polyfillWrapFlushCallback
  ) {
    return;
  }
  var BuiltInHTMLElement = HTMLElement;

  var wrapperForTheName = {
    'HTMLElement': function HTMLElement() {
      return Reflect.construct(
          BuiltInHTMLElement, [], (this.constructor));
    }
  };
  window.HTMLElement = wrapperForTheName['HTMLElement'];
  HTMLElement.prototype = BuiltInHTMLElement.prototype;
  HTMLElement.prototype.constructor = HTMLElement;
  Object.setPrototypeOf(HTMLElement, BuiltInHTMLElement);
})();


//
window.toArray = function(e) {
    return Array.prototype.slice.call(e)
}


// LOGGING (HACK)

Object.defineProperty(Object.prototype, "_elm_ffi_log_str_", {
  get() { return this.value },
  set(str) {
    console.log(str)
    this.value = str
  }
})
