module.exports = function navigation(app) {

  app.ports.refresh && app.ports.refresh.subscribe(function() {
    window.location.reload(true);
  });

  app.ports.redirectAndRefresh && app.ports.redirectAndRefresh.subscribe(function(url) {
    setTimeout(function() {
      window.location.replace(url);
    }, 0);
  });

};
