import { Timecode as TimecodeType } from './type';

if (typeof Object.create !== 'function') {
    Object.create = function (o) {
        let F: any = function() {};
        F.prototype = o;
        return new F();
    };
}

function getValueFromDict(dict: any, name: string, default_value: any): any {
    if (dict.hasOwnProperty(name)) {
        return dict[name];
    }
    else {
        return default_value;
    }
}

export const Timecode = {
    framerate: '',
    intFramerate: 0,
    dropFrame: false,
    hours: 0,
    minutes: 0,
    seconds: 0,
    frames: 0,
    frameCount: 0,
    init: function(args: any) {
        let obj = Object.create(this);
        obj.framerate = getValueFromDict(args, 'framerate', '29.97');
        obj.intFramerate = obj.getIntFramerate();
        obj.dropFrame = getValueFromDict(args, 'drop_frame', false);
        obj.hours = false;
        obj.minutes = false;
        obj.seconds = false;
        obj.frames = false;
        obj.frameCount = false;
        obj.set(getValueFromDict(args, 'timecode', 0));
        return obj;
    },
    getIntFramerate: function(): number {
        if (this.framerate === 'ms') {
            return 1000;
        }
        else {
            return Math.round(parseFloat(this.framerate));
        }
    },
    set: function(timecode: string | number | Date): void {
        if (typeof timecode === 'string') {
            this.partsFromString(timecode);
            this.timecodeToFrameNumber();
            this.frameNumberToTimecode();
        }
        else if (typeof timecode === 'number') {
            this.frameCount = timecode;
            this.frameNumberToTimecode();
        }
        else if (timecode instanceof Date) {
            this.frameCount = this.dateToFrameNumber(timecode);
            this.frameNumberToTimecode();
        }
        else {
            // throw an error
        }
    },
    partsFromString: function(timecode: string): void {
        // Parses timecode strings non-drop 'hh:mm:ss:ff', drop 'hh:mm:ss;ff', or milliseconds 'hh:mm:ss:fff'
        if (timecode.length === 11) {
            this.frames = parseInt(timecode.slice(9, 11));
        }
        else if ((timecode.length === 12) && (this.framerate === 'ms')) {
            this.frames = parseInt(timecode.slice(9, 12));
        }
        else {
            throw new Error(`Timecode string parsing error. ${timecode}`);
        }
        this.hours = parseInt(timecode.slice(0, 2));
        this.minutes = parseInt(timecode.slice(3, 5));
        this.seconds = parseInt(timecode.slice(6, 8));
    },
    frameNumberToTimecode: function(): void {
        // Converts frame_count to timecode
        let frame_count = this.frameCount;
        if (this.dropFrame) {
            let parts = this.frameNumberToDropFrameTimecode(frame_count);
            this.hours = parts[0];
            this.minutes = parts[1];
            this.seconds = parts[2];
            this.frames = parts[3];
        }
        else {
            this.hours = frame_count / (3600 * this.intFramerate);
            if (this.hours > 23) {
                this.hours = this.hours % 24;
                frame_count = frame_count - (23 * 3600 * this.intFramerate);
            }
            this.minutes = (frame_count % (3600 * this.intFramerate)) / (60 * this.intFramerate);
            this.seconds = ((frame_count % (3600 * this.intFramerate)) % (60 * this.intFramerate)) / this.intFramerate;
            this.frames = ((frame_count % (3600 * this.intFramerate)) % (60 * this.intFramerate)) % this.intFramerate;
            this.hours = Math.floor(this.hours);
            this.minutes = Math.floor(this.minutes);
            this.seconds = Math.floor(this.seconds);
            this.frames = Math.floor(this.frames);
        }
    },
    timecodeToFrameNumber: function(): void {
        // converts the current timecode to frame_count.
        if (this.dropFrame) {
            this.frameCount = this.dropFrameTimecodeToFrameNumber([this.hours, this.minutes, this.seconds, this.frames]);
        }
        else {
            this.frameCount = (((this.hours * 3600) + (this.minutes * 60) + this.seconds) * this.intFramerate) + this.frames;
        }
    },
    _calculate: function(sign: string, timecodes: Array<string | number | Date | TimecodeType>): void {
        // all timecodes are calculated in place
        let frameCount;
        let timecode: any;
        for (let i = 0; i < timecodes.length; i += 1) {
            // if a string, number or Date is given, convert it to a timecode
            if ((typeof timecodes[i] === 'string') || (typeof timecodes[i] === 'number') || (timecodes[i] instanceof Date)) {
                timecode = Timecode.init({
                    framerate: this.framerate,
                    timecode: timecodes[i],
                    drop_frame: this.dropFrame
                });
            }
            else {
                timecode = timecodes[i];
            }
            // make sure this is a valid timecode
            if (timecode.frameCount) {
                if (timecode.framerate != this.framerate) {
                    throw new Error('Timecode framerates must match to do calculations.');
                }
                if (sign === '-') {
                    frameCount = timecode.frameCount * -1;
                }
                else if (sign === '+') {
                    frameCount = timecode.frameCount;
                }
                else {
                    throw new Error('Expected sign to be + or -.');
                }
                this.frameCount = this.frameCount + frameCount;
                this.frameNumberToTimecode();
            }
        }

    },
    add: function(args: Array<string | number | Date>): void {
        /*
        // This takes one or more Timecode objects as arguments
        // If this has been initialized, add to this, otherwise just add timecodes given.
        var timecodes = [];
        if (this.frameCount) {
            timecodes.push(this);
        }
        */
        this._calculate('+', args);
    },
    subtract: function(args: Array<string | number | Date>): void {
        this._calculate('-', args);
    },
    toString: function(): string {
        const zeroPad = function(number: number): string {
            const pad = (number < 10) ? '0' : '';
            return pad + Math.floor(number);
        };
        const delim = (this.dropFrame) ? ';' : ':';
        return `${zeroPad(this.hours)}:${zeroPad(this.minutes)}:${zeroPad(this.seconds)}${delim}${zeroPad(this.frames)}`;
    },
    frameNumberToDropFrameTimecode: function(frameNumber: number): Array<number> {
        const framerate = parseFloat(this.framerate);
        const drop_frames = Math.round(framerate * 0.066666);
        const frames_per_hour = Math.round(framerate * 60 * 60);
        const frames_per_24_hours = frames_per_hour * 24;
        const frames_per_10_minutes = Math.round(framerate * 60 * 10);
        const frames_per_minute = Math.round(framerate * 60);
        // Roll over clock if greater than 24 hours
        frameNumber = frameNumber % frames_per_24_hours;
        // If time is negative, count back from 24 hours
        if (frameNumber < 0) {
            frameNumber = frames_per_24_hours + frameNumber;
        }
        const d = Math.floor(frameNumber / frames_per_10_minutes);
        const m = frameNumber % frames_per_10_minutes;
        if (m > drop_frames) {
            frameNumber = frameNumber + (drop_frames * 9 * d) + drop_frames * Math.floor((m - drop_frames) / frames_per_minute);
        }
        else {
            frameNumber = frameNumber + drop_frames * 9 * d;
        }
        return [
            Math.floor(Math.floor(Math.floor(frameNumber / this.intFramerate) / 60) / 60),
            Math.floor(Math.floor(frameNumber / this.intFramerate) / 60) % 60,
            Math.floor(frameNumber / this.intFramerate) % 60,
            frameNumber % this.intFramerate,
        ];
    },
    setFrameNumberToTimecode: function(frameNumber: number): void {
        const framerate = parseFloat(this.framerate);
        const frames_per_hour = Math.round(framerate * 60 * 60);
        const frames_per_24_hours = frames_per_hour * 24;
        // Roll over clock if greater than 24 hours
        frameNumber = frameNumber % frames_per_24_hours;
        // If time is negative, count back from 24 hours
        if (frameNumber < 0) {
            frameNumber = frames_per_24_hours + frameNumber;
        }
        this.hours = Math.floor(Math.floor(Math.floor(frameNumber / this.intFramerate) / 60) / 60);
        this.minutes = Math.floor(Math.floor(frameNumber / this.intFramerate) / 60) % 60;
        this.seconds = Math.floor(frameNumber / this.intFramerate) % 60;
        this.frames = frameNumber % this.intFramerate;
        this.timecodeToFrameNumber();
    },
    dropFrameTimecodeToFrameNumber: function(timecodeAsList: Array<number>): number {
        const [hours, minutes, seconds, frames] = timecodeAsList;
        const drop_frames = Math.round(parseFloat(this.framerate) * 0.066666);
        const hour_frames = this.intFramerate * 60 * 60;
        const minute_frames = this.intFramerate * 60;
        const total_minutes = (hours * 60) + minutes;
        const frame_number = ((hour_frames * hours) + (minute_frames * minutes) + (this.intFramerate * seconds) + frames) - (drop_frames * (total_minutes - Math.floor(total_minutes / 10)));
        return frame_number;
    },

    /**
     * Converts the hour, minute, second, millisecond part of Date() object to the number of
     * frames using the current framerate
     * @param dt {Date}
     * @returns {number}
     */
    dateToFrameNumber: function(dt: Date): number {
        const midnight = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 0, 0, 0);
        return Math.floor(((dt.getTime() - midnight.getTime()) / 1000) * parseFloat(this.framerate));
    }
};
