module.exports = function(app) {

var env = window.nomalab.aws
var Evaporate = require('evaporate')
var SparkMD5 = require('spark-md5')
var sha256 = require('js-sha256').sha256
var evaporateInstance

var uploadCount = 0;
var wakeLocking = null;

var elmToJs = app.ports.uploadToJs
var jsToElm = app.ports.uploadToElm

function evaporateIdGen(key) {
  return decodeURIComponent(evaporateInstance.config.bucket + '/' + key);
}

elmToJs.subscribe(function(evt) {
  console.log("Upload to Js", evt.name, evt.event)
  switch(evt.name) {
    case 'Upload':
      upload(evt.event)
      break;

    case 'Pause':
      evaporateInstance.pause(evaporateIdGen(evt.event), { force : true })
      break;

    case 'Resume':
      evaporateInstance.resume(evaporateIdGen(evt.event))
      break;

    case 'Cancel':
      evaporateInstance.cancel(evaporateIdGen(evt.event))
      break;

  }
})

function onEvent(name, event) {
  // console.log("Upload to Elm", name, event)
  jsToElm.send({ name: name, event: event })
}

Evaporate.create({
  signerUrl: "/v3/aws/signature", // TODO: put in env
  aws_key: env.awsKey,
  bucket: env.s3Bucket,
  awsRegion: env.awsRegion,
  awsSignatureVersion: env.awsSigVersion.slice(-1),
  s3Acceleration: true,
  logging: false,
  onlyRetryForSameFileName: true,
  cryptoMd5Method: function (data) {
    return btoa(SparkMD5.ArrayBuffer.hash(data, true));
  },
  // cryptoHmacMethod: function (signingKey, stringToSign) {
  //   return '';
  // },
  cryptoHexEncodedHash256: function (data) {
    return sha256(data)
  },
  computeContentMd5: true,
  allowS3ExistenceOptimization: true,
  s3FileCacheHoursAgo: 5,
  partSize: 100 * 1024 * 1024,// max 10 000 part => 100 Mo * 1048 Go
  encodeFilename: false,
  cloudfront: true
}).then(function (instance) {
  if (instance.supported) {
    evaporateInstance = instance
  } else {
    onUnsupported()
  }
}).catch(function (err) {
  console.log("couldn't start Evaporate", err)
  onUnsupported()
})

function onUnsupported() {
  onEvent("Unsupported")
}

function upload(settings) {
  var source = settings.source;
  var fileId = settings.fileId;
  var key = settings.key;
  var userId = settings.userId;
  var file = settings.file;


  // This is actually the same id as the internal of Evaporate
  // but do not rely on it
  var evaporateId = evaporateIdGen(key);

  var lastProgress = 0, progressThrottle = 10000;

  var config = {
    name: key,
    file: file.blob,
    xAmzHeadersAtInitiate: {
      'x-amz-meta-user': '' + userId,
      'x-amz-meta-filename': '' + btoa(encodeURI(file.name)),
      'x-amz-meta-mime_type': '' + encodeURI(file.mimeType),
      'x-amz-meta-upload_start_time': (new Date()).toISOString()
    },
    started: onStarted,
    pausing: onPausing,
    paused: onPaused,
    resumed: onResumed,
    complete: onComplete,
    error: onError,
    uploadInitiated: onUploadInitiated,
    cancelled: onCancelled,
    progress: function (percentage, stats) {
      if (percentage >= 1 || Date.now() - progressThrottle > lastProgress) {
        lastProgress = Date.now();
        onProgress(percentage, stats);
      }
    },
  }

  try {
    evaporateInstance.add(config, {
      signParams: {
        fileId: fileId,
        source: source,
      }
    }).catch(function (err) {
      // the user probably cancelled the operation
      // but we will react on the event rather than here
      console.log("Upload break", err)
    });

    onInit()
  } catch (err) {
    onError(err)
  }

  function onInit() {
    uploadStarted();

    onEvent("Created", {
      id : evaporateId,
      fileId : fileId,
      s3Id : null,
      name : file.name,
      progress : 0,
      progressedAt : new Date().toISOString(),
      pausing : false,
      pausedAt : null,
      size : file.size,
      mimeType : file.mimeType,
      blob : file.blob,
      secondsLeft : null,
      completedAt : null,
      error : null
    })
  }

  function onStarted() {
    onEvent("Started", {
      fileId: fileId
    })
  }

  function onComplete(xhr, awsObjectKey, stats) {
    uploadEnded();

    onEvent("Completed", {
      fileId: fileId,
      stats: normalizeStats(stats),
      s3Key: awsObjectKey,
      completedAt : new Date().toISOString()
    })
  }

  function onError(error) {
    uploadEnded();

    onEvent("Error", {
      fileId: fileId,
      error: error.toString()
    })
  }

  function onProgress(percentage, stats) {
    onEvent("Progress", {
      fileId: fileId,
      stats: normalizeStats(stats),
      progress: percentage,
      at: new Date().toISOString()
    })
  }

  function onPausing() {
    onEvent("Pausing", {
      fileId: fileId
    })
  }

  function onPaused() {
    uploadEnded();
    onEvent("Paused", {
      fileId: fileId,
      at: new Date().toISOString()
    })
  }

  function onResumed() {
    uploadStarted();
    onEvent("Resumed", {
      fileId: fileId
    })
  }

  function onUploadInitiated(s3UploadId) {
    onEvent("UploadInitiated", {
      fileId: fileId,
      s3UploadId: s3UploadId
    })
  }

  function onNameChanged(awsObjectKey) {
    onEvent("NameChanged", {
      fileId: fileId,
      s3Key: awsObjectKey
    })
  }

  function onCancelled() {
    uploadEnded();
    onEvent("Cancelled", {
      fileId: fileId
    })
  }
}

function uploadStarted() {
  uploadCount++;

  if ('wakeLock' in navigator && !wakeLocking) {
    navigator.wakeLock.request('screen').then(function(w) {
      wakeLocking = w;
    });
  }
}

function uploadEnded() {
  uploadCount--;

  if (uploadCount <1 && wakeLocking && wakeLocking.release) {
    wakeLocking.release('screen');
    wakeLocking = null;
  }
}


function normalizeStats(stats) {
  if (stats.secondsLeft < 0) {
    stats.secondsLeft = null;
  } else {
    stats.secondsLeft = stats.secondsLeft
  }

  // Looks like Evaporate might have a speed at NaN when resuming an upload
  if (isNaN(stats.speed)) {
    stats.speed = 0;
    stats.readableSpeed = '';
  }

  return stats;
}


window.addEventListener('beforeunload', function (e) {
  if (uploadCount > 0) {
    var message = "Vous avez des uploads en cours, voulez-vous abandonner ?"
    e.returnValue = message;
    return message;
  } else {
    return true;
  }
});


} // enf of exports
