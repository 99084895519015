/*
 * common.js
 */

exports.pause18 = '<svg height="18px" width="18px" viewBox="0 0 18 18" class="IconIcon18"><rect class="Bg" height="18" width="18" x="0" y="0"></rect><rect class="Fg" height="8" width="3" x="5" y="5"></rect><rect class="Fg" height="8" width="3" x="10" y="5"></rect></svg>';
exports.play18 = '<svg height="18px" width="18px" viewBox="0 0 18 18" class="IconIcon18"><rect class="Bg" height="18" width="18" x="0" y="0"></rect><polygon class="Fg" points="6 5 12 9 6 13"></polygon></svg>';

exports.$ = function $(s, el) {
    return (el || window.document).querySelector(s);
};

exports.$$ = function $$(s, el) {
    return (el || window.document).querySelectorAll(s);
};

exports.create = function create(t) {
    return window.document.createElement(t);
};

exports.logger = function logger(msg) {
  if(window.NOMALAB_DEBUG_MODE) {
    let m = (typeof msg == 'object') ? JSON.stringify(msg, null, 2) : msg;
    console.log('%c[NOMALAB DEBUG] ' + m);
  }
};

exports.fixSubtitlePosition = function fixSubtitlePosition(video) {
  if(!video.textTracks[0] || !video.textTracks[0].cues) {
    console.log(video.textTracks)
    return;
  }
  let l = video.textTracks[0].cues.length;
  for (let i=0; i<l; i++){
    let cue = video.textTracks[0].cues[i];
    if (cue.line==0) {
      cue.line = 1;
    }
    video.textTracks[0].cues[i] = cue;
  }
};
