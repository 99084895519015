import { Timecode } from './timecode';

import {
  InputMaskTc,
  Timecode as TimecodeType
} from './type';

export function inputMaskTimecode(el: HTMLInputElement, minFrame: number, maxFrame: number, frameRate: number, cb: (tc: TimecodeType) => void): InputMaskTc {
  let savedTC: TimecodeType = Timecode.init({ framerate: frameRate });
  let isEditing = false;

  function doNothing(e: any) {
    e.preventDefault();
    e.stopImmediatePropagation();
    return false;
  }

  function formatTC(value: string) {
    let segments = value.split(':').map((i: string) => i.padStart(2, '0') );
    while (segments.length < 4) {
      segments.unshift('00');
    }
    return segments.join(':');
  }

  const numbers = ['0','1','2','3','4','5','6','7','8','9'];

  const autorizedKeyCodes = [
    37, // left
    39, // right
    38, // down
    40, // up
    8,  // backspace
    46, // delete
  ];

  function onFocus() {
    isEditing = true;
    savedTC = Timecode.init({ framerate: frameRate });
    savedTC.set(el.value);
  }

  function updateValue() {
    try {
      let nextTC = Timecode.init({ framerate: frameRate });
      nextTC.set(formatTC(el.value));
      if (minFrame > nextTC.frame_count) {
        nextTC.set(minFrame);
      }
      else if (maxFrame < nextTC.frame_count) {
        nextTC.set(maxFrame);
      }
      savedTC = nextTC;
      savedTC.timecodeToFrameNumber();
      cb(savedTC);
      el.value = savedTC.toString();
    } catch(e) {
      el.value = savedTC.toString();
    }
    isEditing = false;
  }

  function onKeyDown(e: any): boolean | undefined {
    if (autorizedKeyCodes.includes(e.keyCode) || e.ctrlKey || e.metaKey) {
      e.stopImmediatePropagation();
      return true;
    }
    else if (e.keyCode == 13 || e.keyCode == 27) {
      el.blur();
    }
    else if (numbers.includes(e.key)) {
      let value = el.value;
      let position = el.selectionStart;
      let newChar = e.key;
      if (position) {
        let prevChar = parseInt(value.charAt(position - 1));
        let prev2Char = parseInt(value.charAt(position - 2));
        let nextChar = value.charAt(position);
        let notSelecting = position == el.selectionEnd;

        // Can't have more than 2 consecutive digits
        if (!isNaN(prev2Char) && !isNaN(prevChar)) {
          doNothing(e);
        // Automatically add ':' when typing a number and when it's safe
        }
        else if (!isNaN(prevChar) && nextChar != ':' && canHaveMoreSegments() && notSelecting) {
          el.value =  value.slice(0, position) + newChar + ':' + value.slice(position);
          el.setSelectionRange(position+2,position+2);
          doNothing(e);
        }
      }
    }
    else if (e.key == ':') {
      // limit to 3 colons ':'
      if (!canHaveMoreSegments()) {
        return doNothing(e);
      }
    }
    else {
      return doNothing(e);
    }
  }

  function canHaveMoreSegments() {
    return el.value.split(':').length < 4;
  }

  function setTc(tc: TimecodeType): void {
    if (isEditing) {
      return;
    }
    savedTC = tc;
    el.value = savedTC.toString();
  }

  function setTcFromFrame(frame: number): void {
    if (isEditing) {
      return;
    }
    savedTC.setFrameNumberToTimecode(frame);
    el.value = savedTC.toString();
  }

  function init(): void {
    el.addEventListener('focus', onFocus);
    el.addEventListener('blur', updateValue);
    el.addEventListener('keydown', onKeyDown);
  };

  function destroy(): void {
    el.removeEventListener('focus', onFocus);
    el.removeEventListener('blur', updateValue);
    el.removeEventListener('keydown', onKeyDown);
  };

  init();

  return {
    destroy: destroy,
    setTc: setTc,
    setTcFromFrame: setTcFromFrame
  };

};
