export function render(imageUrl: string): string {
  return `<html>
<head>
  <title>Nomalab</title>                                                  
</head>
<body style="background:#555D68; text-align:center">
  <style>
    @keyframes anim_spin {
      from { transform: rotate(0deg); } 
      to { transform: rotate(359deg); }
    }
    span {
      font-family: Open Sans, Roboto,sans-serif;
      font-size:13px;
      font-weight: 500;
      color: #CFD1D5;
      display: block;
    }
    svg {
      animation: anim_spin 1s linear infinite;
      fill: #3BAAFF;
    }
    div {
      margin: 250px 0 0 0;
    }
  </style>
  <div id="nomalabSpinner">
    <svg height="40px" viewBox="0 0 40 40" width="40px">
      <g transform="translate(5, 9)">
        <path d="M15,0 C21.0751322,0 26,4.92486775 26,11 C26,17.0751322 21.0751322,22 15,22 C9.26195744,22 4.55007179,17.6065 4.04484302,12 L6.05492878,12 C6.55237307,16.4999505 10.3674463,20 15,20 C19.9705627,20 24,15.9705627 24,11 C24,6.02943725 19.9705627,2 15,2 L15,0 Z"/>
        <polygon points="5 6 10 12 0 12"/>
      </g>
    </svg>
    <span>Extraction en cours...</span>
  </div>
  <script>
    var i = document.createElement('img');
    var loadedCb = function () {
      window.nomalabSpinner.style.display = 'none';
      window.document.body.appendChild(i);
    };

    var errorCb = function (err) {
      document.querySelector('#nomalabSpinner svg').style.display = 'none';
      document.querySelector('#nomalabSpinner span').innerHTML = 'Une erreur est survenue. Merci de contacter notre service opérations via le chat ou par mail à ops@nomalab.com';
    };
    
    i.onload = loadedCb;
    i.onerror = errorCb;
    i.style = "width: 100%";
    
    // get url and then create IMAGE

    let myRequest = new Request('${imageUrl}');

    fetch(myRequest)
    .then(function(response) {
      if (!response.ok) {
        throw new Error(\`HTTP error! status: \${response.status}\`);
      }
      return response.text();
    })
    .then(function(imgUrl) {
      i.src = imgUrl;
    });

  </script>
  </body>
</html>`
}



