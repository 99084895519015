module.exports = function keyboard(app) {
  window.addEventListener('keypress', function (e) {
    if (e.target.tagName === "INPUT" || e.target.tagName === "SELECT" || e.target.tagName === "TEXTAREA") {
      // filling a form...
    } else {
      app.ports.onKeypressNoInput.send(e.keyCode);
    }
  });
  // window.addEventListener('keydown', function (e) {
  //   if (e.target.tagName === "INPUT" || e.target.tagName === "SELECT" || e.target.tagName === "TEXTAREA") {
  //     // filling a form...
  //   } else {
  //     // do not scroll
  //     if (e.keyCode == 32) e.preventDefault();
  //     app.ports.onKeydownNoInput.send(e.keyCode);
  //   }
  // });
  // window.addEventListener('keyup', function (e) {
  //   if (e.target.tagName === "INPUT" || e.target.tagName === "SELECT" || e.target.tagName === "TEXTAREA") {
  //     // filling a form...
  //   } else {
  //     app.ports.onKeyupNoInput.send(e.keyCode);
  //   }
  // });
};
