var fns = require('date-fns');
var fnsLocales = require('date-fns/locale');
var localeFns = { locale: fnsLocales.fr };

/*
 * <format-date datetime="2020-06-04T12:27:06.222Z">
 */
class FormatDate extends HTMLElement {

  constructor() {
    super();
  }

  connectedCallback() {
    this.render();
    this.onclick = function() {
      this.render();
    }
  }

  render() {
   var x = new Date(this.getAttribute('datetime'));
   this.innerHTML = fns.format(x, 'PP', localeFns);
 }
}


customElements.define('format-date', FormatDate);
