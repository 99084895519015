module.exports = function raf(app) {
  var lastTime = 0;
  var vendors = ['ms', 'moz', 'webkit', 'o'];
  var usingSetTimeout = false

  if(!window.requestAnimationFrame) {
    vendors.forEach(function (prefix) {
      window.requestAnimationFrame = window[prefix +'RequestAnimationFrame'];
      window.cancelAnimationFrame = window[prefix +'CancelAnimationFrame']
                                 || window[prefix +'CancelRequestAnimationFrame'];
    })
  }

  if (!window.requestAnimationFrame) {
    usingSetTimeout = true;

    window.requestAnimationFrame = function(callback, element) {
        var currTime = new Date().getTime();
        var timeToCall = Math.max(0, 16 - (currTime - lastTime));
        var id = window.setTimeout(function() {
          callback(currTime + timeToCall);
        }, timeToCall);
        lastTime = currTime + timeToCall;
        return id;
    };
  }

  if (!window.cancelAnimationFrame) {
    if (usingSetTimeout) {
      window.cancelAnimationFrame = function(id) {
        clearTimeout(id);
      };
    } else {
      window.cancelAnimationFrame = function() {};
    }
  }

  return app
};
