var fns = require('date-fns');
var fnsLocales = require('date-fns/locale');
var localeFns = { locale: fnsLocales.fr };


/*
 * <format-distance startdate="2020-06-04T12:27:06.222Z" enddate="2021-06-04T12:27:06.222Z">
 */
class DistanceTime extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.render();
  }

  render() {
   var start = new Date(this.getAttribute('startdate'));
   var end = new Date(this.getAttribute('enddate'));
   this.innerHTML = fns.formatDistanceStrict(end, start, localeFns);
 }
}

customElements.define('format-distance', DistanceTime);
