module.exports = function player(app) {

  var key = 'nomalabLastAudioQualification';

  function init() {
    if(localStorage.getItem(key) === null) {
      localStorage.setItem(key, JSON.stringify({}));
    }
  }

  function clean() {
    if(localStorage.getItem(key) != null) {
      localStorage.setItem(key, JSON.stringify({}));
      app.ports.content.send(getContent());
    }
  }

  function set(k, v, l, t) {
    var s = JSON.parse(localStorage.getItem(key));
    s[k] = { v: v, l:l, t:t };
    localStorage.setItem(key, JSON.stringify(s));
  }

  function get(record) {
    return {
      fileId: record.fileId,
      channels: record.channels,
      result: getContent()
    };
  }

  function getContent() {
    var store = JSON.parse(localStorage.getItem(key));
    var r = [];
    Object.keys(store).forEach(function (k) {
      r.push({
        index: parseInt(k, 10),
        version: store[k].v,
        layout: store[k].l,
        typeVersion: store[k].t
      });
    });

    return r;
  }

  init();

  app.ports.get.subscribe(function (record) {
    app.ports.lastAudioQualification.send(get(record));
  });

  app.ports.info.subscribe(function () {
    app.ports.content.send(getContent());
  });

  app.ports.clean.subscribe(clean);

  app.ports.save.subscribe(function (recordList) {
    recordList.forEach(function (record) {
      if(record.layout !== null && record.typeVersion !== null) {
        set(record.index, record.version, record.layout, record.typeVersion);
      }
    });
    app.ports.content.send(getContent());
  });

  return app;
};
