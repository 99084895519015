/* braille.js
 *
 * Read the frame number coded in braille code
 * from the given video element.
 * returns NaN if failures while decoding
 *
 * braille : VideoHtmlElement -> Integer
 */

module.exports = function () {

  const numberOfSymbols = 7;
  const width = 392;
  const height = 60;
  const symbolWidth = width / numberOfSymbols;
  const symbolHeight = height;
  const colorMedian = 125;

  var brailleCoordinates = [
    [1 / 4, 1 / 6], [3 / 4, 1 / 6],
    [1 / 4, 3 / 6], [3 / 4, 3 / 6],
    [1 / 4, 5 / 6], [3 / 4, 5 / 6]
  ];
  var braille1 = JSON.stringify([
    1, 0,
    0, 0,
    0, 0
  ]);
  var braille2 = JSON.stringify([
    1, 0,
    1, 0,
    0, 0
  ]);
  var braille3 = JSON.stringify([
    1, 1,
    0, 0,
    0, 0
  ]);
  var braille4 = JSON.stringify([
    1, 1,
    0, 1,
    0, 0
  ]);
  var braille5 = JSON.stringify([
    1, 0,
    0, 1,
    0, 0
  ]);
  var braille6 = JSON.stringify([
    1, 1,
    1, 0,
    0, 0
  ]);
  var braille7 = JSON.stringify([
    1, 1,
    1, 1,
    0, 0
  ]);
  var braille8 = JSON.stringify([
    1, 0,
    1, 1,
    0, 0
  ]);
  var braille9 = JSON.stringify([
    0, 1,
    1, 0,
    0, 0
  ]);
  var braille0 = JSON.stringify([
    0, 1,
    1, 1,
    0, 0
  ]);

  // Would be better to test for all rgb to be exactly 0
  // but it appears sometime the value will be 2 or 3 or 4
  // so we just check it is in the lower half of the color range
  // (aka below 255/2, nearly)
  function isBlack(rgba) {
    return (rgba[0] < colorMedian) && (rgba[1] < colorMedian) && (rgba[2] < colorMedian)
  }

  function parse(video) {
    let decodeCanvas = document.createElement('canvas');
    decodeCanvas.width = width;
    decodeCanvas.height = height;
    decodeCanvas.crossorigin = "anonymous";
    let decodeContext = decodeCanvas.getContext('2d');
    let frameNumber = ''
    if (!video) return 0;
    // void ctx.drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight)
    // braille font width is 56, height is 60 and drawn in the upper-left corner of
    // the video. we render 7 characters on each frame, making the total width 392.
    // however the 56px width includes letter-spacing, the interesting part of
    // a braille character is only 50px, so we take that into a account when decoding
    // the character.
    decodeContext.drawImage(video, 0, 0, width, height, 0, 0, width, height);

    // iterate over braille characters
    for (var i = 0; i < numberOfSymbols; i++) {
      let candidate = JSON.stringify(
        // map over braille dots
        brailleCoordinates.map(function (rationalDotCoords) {
          // those are the coordinates of the center-most pixel of
          // the braille dot we are currently decoding
          var x = (50 * rationalDotCoords[0]) + (symbolWidth * i);
          var y = rationalDotCoords[1] * symbolHeight;
          var pixelData = decodeContext.getImageData(x, y, 1, 1).data;
          return isBlack(pixelData) ? 0 : 1;
        })
      );

      let decoded
      switch (candidate) {
        case braille0: decoded = '0'; break;
        case braille1: decoded = '1'; break;
        case braille2: decoded = '2'; break;
        case braille3: decoded = '3'; break;
        case braille4: decoded = '4'; break;
        case braille5: decoded = '5'; break;
        case braille6: decoded = '6'; break;
        case braille7: decoded = '7'; break;
        case braille8: decoded = '8'; break;
        case braille9: decoded = '9'; break;
        // returning NaN if decode failed
        default: decoded = 'oops';
      }

      frameNumber += decoded;
    }

    return parseInt(frameNumber, 10);   
  }

  return parse;

}();
